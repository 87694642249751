import { gebi, sleep } from '@utils/toolbox';

const Defaults = {
	breakpoints: {
		mobile: 768
	},
	html: {
		header: `<span>header</span>`,
		body: `<span>Body</span>`,
		footer: `<button class="js-close-modal" style="margin-left: auto">Close</button>`
	}
};

export class Modal {
	/**
	 * @param {object} opts
	 * @param {string} [opts.id='modal'] - The id of the modal
	 * @param {string} [opts.className=''] - The class of the modal
	 * @param {boolean} [opts.hideHeader=false] - Hide the header
	 * @param {boolean} [opts.hideFooter=false] - Hide the footer
	 * @param {boolean} [opts.resetScroll=false] - Reset the scroll position on close
	 * @param {boolean} [opts.hideOnSwipe=false] - Hide the modal on swipe
	 * @param {boolean} [opts.hideMobileUp=false] - Hide the modal on mobile
	 * @param {object} [opts.callbacks={}] - Callbacks
	 * @param {function} [opts.callbacks.onInit] - Callback on init
	 * @param {function} [opts.callbacks.onOpen] - Callback on open
	 * @param {function} [opts.callbacks.onClose] - Callback on close
	 * @param {string} opts.header
	 * @param {string} opts.body
	 * @param {string} opts.footer
	 * @returns {Modal}
	 */
	constructor (opts) {
		this._defaults = opts && opts.defaults ? Object.assign(Defaults, opts.defaults) : Defaults;
		this._id = opts && opts.id ? opts.id : `modal-${Date.now()}`;
		this._className = opts && opts.className ? opts.className : '';
		this._hideHeader = opts && opts.hideHeader ? true : false;
		this._hideFooter = opts && opts.hideFooter ? true : false;
		this._resetScroll = opts && opts.resetScroll ? true : false;
		this._hideOnSwipe = opts && opts.hideOnSwipe ? true : false;
		this._hideOnHistoryBack = opts?.hideOnHistoryBack ?? false;
		this._hideOnHistoryBackTriggered = false;
		this._hideMobileUp = opts && opts.hideMobileUp ? true : false;
		this._modalId = `${this._id}__modalOverlay`;
		this._events = {};
		this._callbacks = {
			onInit: opts && opts.onInit ? opts.onInit : null,
			onOpen: opts && opts.onOpen ? opts.onOpen : null,
			onClose: opts && opts.onClose ? opts.onClose : null
		};

		this.html = null;
		this.dialog = null;
		this.isOpen = false;
		this.isClosing = false;

		this.render({
			header: opts?.header || this._defaults.html.header,
			body: opts?.body || this._defaults.html.body,
			footer: opts?.footer || this._defaults.html.footer
		});

		this.events();

		if (typeof this._callbacks.onInit === 'function') {
			this._callbacks.onInit();
		}

		return this;
	}

	render (content) {
		const { header, body, footer } = content;

		let styles = 'modal';

		if (this._className) {
			styles += ` ${this._className}`;
		}

		if (this._hideHeader) {
			styles += ' modal--hideHeader';
		}

		if (this._hideFooter) {
			styles += ' modal--hideFooter';
		}

		if (this._hideMobileUp) {
			styles += ' modal--hideMobileUp';
		}

		const html = `
				<div id="${this._id}" class="${styles}" tabIndex="-1" role="dialog">
					<div class="modal__dialog" role="document">
						<div class="modal__content">
							<div class="modal__header">${header}</div>
							<div class="modal__body">${body}</div>
							${!this._hideFooter ? `<div class="modal__footer">${footer}</div>` : ''}
						</div>
					</div>
				</div>
			`;

		document.body.insertAdjacentHTML('beforeend', html);

		this.html = document.querySelector(`#${this._id}`);
		this.dialog = this.html.querySelector('.modal__dialog');

		// Push the modal to the history stack
		if (this._hideOnHistoryBack) {
			window.history.pushState({ modal: true }, '');
		}
	}

	renderOverlay () {
		const overlay = document.createElement('div');

		overlay.id = this._modalId;

		overlay.classList.add(`modalOverlay`);

		if (this._hideMobileUp) {
			overlay.classList.add('modalOverlay--hideMobileUp');
		}

		document.body.appendChild(overlay);
	}

	showOverlay () {
		if (!gebi(this._modalId)) {
			this.renderOverlay();
		}
	}

	removeOverlay () {
		gebi(this._modalId)?.remove?.();
	}

	open () {
		if (this.isClosing) {
			return;
		}

		this.showOverlay();

		this.html.classList.add('modal--open');

		window.setTimeout(() => this.dialog.classList.add('modal__dialog--fadeIn'), 100);

		if (!this._hideMobileUp) {
			document.body.classList.add('modal-open');
		} else {
			if (window.innerWidth < this._defaults.breakpoints.mobile) {
				document.body.classList.add('modal-open');
			}
		}

		if (typeof this._callbacks.onOpen === 'function') {
			this._callbacks.onOpen();
		}

		this.isOpen = true;
		this.html.focus();

		return this;
	}

	async close () {
		if (this.isClosing || this.dialog === null) {
			return;
		}

		this.dialog.classList.add('modal__dialog--fadeOut');

		await sleep(300);
		this.removeOverlay();
		this.isClosing = true;

		if (!this.html?.classList || !this.dialog?.classList) {
			return;
		}

		document.body.classList.remove('modal-open');
		this.html.classList.remove('modal--open');
		this.dialog.classList.remove('modal__dialog--fadeIn', 'modal__dialog--fadeOut');
		this.isOpen = false;

		// Remove the modal from the history stack if the user didn't go back
		if (this._hideOnHistoryBack && !this._hideOnHistoryBackTriggered) {
			window.history.back();
		}

		// Reset the flag
		if (this._hideOnHistoryBack) {
			this._hideOnHistoryBackTriggered = false;
		}

		if (this._resetScroll) {
			this.resetScrollPositions();
		}

		if (typeof this._callbacks.onClose === 'function') {
			this._callbacks.onClose();
		}

		this.destroy();
		this.isClosing = false;

		return this;
	}

	resetScrollPositions () {
		this.html.querySelector('.modal__body').scrollTop = 0;
		this.html.scrollTop = 0;
	}

	update (element, html) {
		const elem = this.html.querySelector(`.modal__${element}`);

		if (!elem) {
			return new Error('Please provide a valid Modal element to update.');
		}

		elem.innerHTML = html;

		this.resetScrollPositions();

		return this;
	}

	destroy () {
		if (!this?.html) {
			return;
		}

		if (this._hideOnHistoryBack) {
			window.removeEventListener('popstate', this._events.popState);
		}

		this.html.removeEventListener('click', this._events.click);

		document.removeEventListener('click', this._events.docClick);
		document.removeEventListener('click', this._events.docKeyUp);

		if (this._hideMobileUp) {
			window.removeEventListener('resize', this._events.winResize);
		}

		if (this._hideOnSwipe) {
			document.removeEventListener('touchstart', this._events.docTouchStart);
			document.removeEventListener('touchmove', this._events.docTouchMove);
			document.removeEventListener('touchend', this._events.docTouchEnd);
		}

		this.html?.remove?.();
		this.removeOverlay();

		this._defaults = null;
		this._id = null;
		this._className = null;
		this._resetScroll = null;
		this._hideHeader = null;
		this._hideFooter = null;
		this._hideOnSwipe = null;
		this._hideMobileUp = null;
		this._events = null;
		this._callbacks = null;

		this.html = null;
		this.dialog = null;
		this.isOpen = null;
		this.isClosing = null;

		return null;
	}

	events () {
		if (this._hideOnHistoryBack) {
			this._events.popState = (event) => {
				this._hideOnHistoryBackTriggered = true;
				event.preventDefault();
				if (this.isOpen) {
					this.close();
				}
			};
			window.addEventListener('popstate', this._events.popState);
		}

		this._events.click = (e) => {
			const target = e.target;

			if (target.classList.contains('js-close-modal')) {
				e.stopPropagation();

				this.close();
			}
		};

		this._events.docClick = (e) => {
			const target = e.target;

			if (target.classList.contains('modalOverlay')) {
				e.stopPropagation();

				this.close();
			}
		};

		this._events.docKeyUp = (e) => {
			if (this.isOpen) {
				if (e.isComposing || e.keyCode === 27) {
					e.preventDefault();

					this.close();
				}
			}
		};

		this.html.addEventListener('click', this._events.click);

		document.addEventListener('click', this._events.docClick);
		document.addEventListener('keyup', this._events.docKeyUp);

		if (this._hideMobileUp) {
			this._events.winResize = (e) => {
				if (this.isOpen) {
					if (window.innerWidth < this._defaults.breakpoints.mobile) {
						document.body.classList.add('modal-open');
					} else {
						document.body.classList.remove('modal-open');
					}
				}
			};

			window.addEventListener('resize', this._events.winResize);
		}

		if (this._hideOnSwipe) {
			let modalScrolled = false;
			let startTouchPos = 0;
			let stopYPos = 0;
			let moveYDist = 0;

			const content = this.html.querySelector('.modal__content');

			this._events.docTouchStart = (e) => {
				if (window.innerWidth > 768) {
					return;
				}

				const elem = e.target;

				if (elem.closest(`#${this._id}`)) {
					modalScrolled = this.html.scrollTop || content.scrollTop ? true : false;
					startTouchPos = e.touches[0].clientY;
				}
			};

			this._events.docTouchMove = (e) => {
				if (window.innerWidth > 768) {
					return;
				}

				const elem = e.target;

				if (elem.closest(`#${this._id}`)) {
					const distance = e.touches[0].clientY - startTouchPos;

					if (distance > 0) {
						if (modalScrolled) {
							return;
						}

						if (typeof e.cancelable !== 'boolean' || e.cancelable) {
							e.preventDefault();
						}

						e.stopPropagation();

						stopYPos = e.touches[0].clientY;
						moveYDist = stopYPos - startTouchPos;

						this.html.classList.add('modal--touch');
						this.html.style.transform = `translate3d(0, ${distance}px, 0)`;
					}
				}
			};

			this._events.docTouchEnd = (e) => {
				if (window.innerWidth > 768) {
					return;
				}

				const elem = e.target;

				if (elem.closest(`#${this._id}`)) {
					if (moveYDist > 120) {
						this.html.style.transform = '';
						this.html.classList.remove('modal--touch');
						this.html.classList.add('modal--hidden');

						this.close();
					} else {
						this.html.classList.remove('modal--touch');
						this.html.style.transform = '';
					}

					startTouchPos = 0;
					stopYPos = 0;
					moveYDist = 0;
				}
			};

			document.addEventListener('touchstart', this._events.docTouchStart, { passive: false });
			document.addEventListener('touchmove', this._events.docTouchMove, { passive: false });
			document.addEventListener('touchend', this._events.docTouchEnd);
		}
	}
}
